import * as React from 'react';
import styled from 'styled-components';

const Ships = () => {
  const [shipList, setShipList] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      const response = await fetch('/ships.json');
      const data = await response.json();
      setShipList(data);
    })();
  }, []);

  return (
    <Box>{shipList.map(ship => (
      <ShipBlock key={ship.ship_code}>
        <BgFrame />
        <MainFrame />
        <ShipContent>
          <ShipImage imageSource={ship.image} />
          <ShipInfo>
            <ShipName href={ship.link} target="_blank" referrerPolicy="no-referrer">{ship.name}</ShipName>
            {(Object.keys(ship).includes('flyable') && !ship.flyable) && (
              <NotFlyableCallout>Not Flyable</NotFlyableCallout>
            )}
            <ShipManufacturer>By {ship.manufacturer_name}</ShipManufacturer>
          </ShipInfo>
        </ShipContent>
      </ShipBlock>
    ))}</Box>
  )

}

export default Ships;

const Box = styled.div`
  margin: 2rem 3rem;
  font-family: 'Minecraft Mono', monospace;
  flex-direction: column;;
`

const ShipBlock = styled.div`
  margin-bottom: 1rem;
  position: relative;
`

const MainFrame = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #fff;
  z-index: 2;
`

const BgFrame = styled.div`
  position: absolute;
  top: 2px;
  left: 2px;
  width: 100%;
  height: 100%;
  border: 2px solid hsla(214, 76%, 49%, 0.5);
  z-index: 1;
`

const ShipContent = styled.div`
  position: relative;
  padding: 2px;
  z-index: 3;
`

const ShipImage = styled.div`
  background-position: center center;
  background-size: cover;
  background-image: url(${props => props.imageSource});
  width: 300px;
  height: 150px;
  flex-shrink: 0;
`

const ShipInfo = styled.div`
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 2rem;
`

const ShipName = styled.a`
  font-size: 1.5rem;
  font-weight: bold;
  text-shadow: 3px 3px 0.5px hsla(214, 76%, 49%, 0.5);
  color: #fff;
  text-decoration: none;

  &:hover {
    color:  hsl(214, 76%, 49%) !important;

    text-decoration: dashed;
  }
`

const ShipManufacturer = styled.div`
  font-size: 1rem;
`;

const NotFlyableCallout = styled.div`
  font-size: 1rem;
  color: hsl(0, 80%, 60%);
`