import logo from './logo.svg';
import './App.css';
import Ships from './Ships';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        orels wastes money on ships
      </header>
      <Ships />
    </div>
  );
}

export default App;
